<template>
    <div class="grid-container">
        <div class="grid-x grid-padding-x">
            <div class="cell small-12">
                <h1>View Legacy Options</h1>
            </div>
        </div>
        <div class="grid-x grid-padding-x">
            <div class="cell small-12">
                <table class="wide-table">
                    <thead>
                        <tr>
                          <th>Market_and_Exchange_Names</th>
                          <th>As_of_Date_In_Form_YYMMDD</th>
                          <th>Report_Date_as_MM_DD_YYYY</th>
                          <th>CFTC_Contract_Market_Code</th>
                          <th>CFTC_Market_Code</th>
                          <th>CFTC_Region_Code</th>
                          <th>CFTC_Commodity_Code</th>
                          <th>Open_Interest_All</th>
                          <th>NonComm_Positions_Long_All</th>
                          <th>NonComm_Positions_Short_All</th>
                          <th>NonComm_Postions_Spread_All</th>
                          <th>Comm_Positions_Long_All</th>
                          <th>Comm_Positions_Short_All</th>
                          <th>Tot_Rept_Positions_Long_All</th>
                          <th>Tot_Rept_Positions_Short_All</th>
                          <th>NonRept_Positions_Long_All</th>
                          <th>NonRept_Positions_Short_All</th>
                          <th>Open_Interest_Old</th>
                          <th>NonComm_Positions_Long_Old</th>
                          <th>NonComm_Positions_Short_Old</th>
                          <th>NonComm_Positions_Spread_Old</th>
                          <th>Comm_Positions_Long_Old</th>
                          <th>Comm_Positions_Short_Old</th>
                          <th>Tot_Rept_Positions_Long_Old</th>
                          <th>Tot_Rept_Positions_Short_Old</th>
                          <th>NonRept_Positions_Long_Old</th>
                          <th>NonRept_Positions_Short_Old</th>
                          <th>Open_Interest_Other</th>
                          <th>NonComm_Positions_Long_Other</th>
                          <th>NonComm_Positions_Short_Other</th>
                          <th>NonComm_Positions_Spread_Other</th>
                          <th>Comm_Positions_Long_Other</th>
                          <th>Comm_Positions_Short_Other</th>
                          <th>Tot_Rept_Positions_Long_Other</th>
                          <th>Tot_Rept_Positions_Short_Other</th>
                          <th>NonRept_Positions_Long_Other</th>
                          <th>NonRept_Positions_Short_Other</th>
                          <th>Change_in_Open_Interest_All</th>
                          <th>Change_in_NonComm_Long_All</th>
                          <th>Change_in_NonComm_Short_All</th>
                          <th>Change_in_NonComm_Spead_All</th>
                          <th>Change_in_Comm_Long_All</th>
                          <th>Change_in_Comm_Short_All</th>
                          <th>Change_in_Tot_Rept_Long_All</th>
                          <th>Change_in_Tot_Rept_Short_All</th>
                          <th>Change_in_NonRept_Long_All</th>
                          <th>Change_in_NonRept_Short_All</th>
                          <th>Pct_of_Open_Interest_All</th>
                          <th>Pct_of_OI_NonComm_Long_All</th>
                          <th>Pct_of_OI_NonComm_Short_All</th>
                          <th>Pct_of_OI_NonComm_Spread_All</th>
                          <th>Pct_of_OI_Comm_Long_All</th>
                          <th>Pct_of_OI_Comm_Short_All</th>
                          <th>Pct_of_OI_Tot_Rept_Long_All</th>
                          <th>Pct_of_OI_Tot_Rept_Short_All</th>
                          <th>Pct_of_OI_NonRept_Long_All</th>
                          <th>Pct_of_OI_NonRept_Short_All</th>
                          <th>Pct_of_Open_Interest_Old</th>
                          <th>Pct_of_OI_NonComm_Long_Old</th>
                          <th>Pct_of_OI_NonComm_Short_Old</th>
                          <th>Pct_of_OI_NonComm_Spread_Old</th>
                          <th>Pct_of_OI_Comm_Long_Old</th>
                          <th>Pct_of_OI_Comm_Short_Old</th>
                          <th>Pct_of_OI_Tot_Rept_Long_Old</th>
                          <th>Pct_of_OI_Tot_Rept_Short_Old</th>
                          <th>Pct_of_OI_NonRept_Long_Old</th>
                          <th>Pct_of_OI_NonRept_Short_Old</th>
                          <th>Pct_of_Open_Interest_Other</th>
                          <th>Pct_of_OI_NonComm_Long_Other</th>
                          <th>Pct_of_OI_NonComm_Short_Other</th>
                          <th>Pct_of_OI_NonComm_Spread_Other</th>
                          <th>Pct_of_OI_Comm_Long_Other</th>
                          <th>Pct_of_OI_Comm_Short_Other</th>
                          <th>Pct_of_OI_Tot_Rept_Long_Other</th>
                          <th>Pct_of_OI_Tot_Rept_Short_Other</th>
                          <th>Pct_of_OI_NonRept_Long_Other</th>
                          <th>Pct_of_OI_NonRept_Short_Other</th>
                          <th>Traders_Tot_All</th>
                          <th>Traders_NonComm_Long_All</th>
                          <th>Traders_NonComm_Short_All</th>
                          <th>Traders_NonComm_Spread_All</th>
                          <th>Traders_Comm_Long_All</th>
                          <th>Traders_Comm_Short_All</th>
                          <th>Traders_Tot_Rept_Long_All</th>
                          <th>Traders_Tot_Rept_Short_All</th>
                          <th>Traders_Tot_Old</th>
                          <th>Traders_NonComm_Long_Old</th>
                          <th>Traders_NonComm_Short_Old</th>
                          <th>Traders_NonComm_Spead_Old</th>
                          <th>Traders_Comm_Long_Old</th>
                          <th>Traders_Comm_Short_Old</th>
                          <th>Traders_Tot_Rept_Long_Old</th>
                          <th>Traders_Tot_Rept_Short_Old</th>
                          <th>Traders_Tot_Other</th>
                          <th>Traders_NonComm_Long_Other</th>
                          <th>Traders_NonComm_Short_Other</th>
                          <th>Traders_NonComm_Spread_Other</th>
                          <th>Traders_Comm_Long_Other</th>
                          <th>Traders_Comm_Short_Other</th>
                          <th>Traders_Tot_Rept_Long_Other</th>
                          <th>Traders_Tot_Rept_Short_Other</th>
                          <th>Conc_Gross_LE_4_TDR_Long_All</th>
                          <th>Conc_Gross_LE_4_TDR_Short_All</th>
                          <th>Conc_Gross_LE_8_TDR_Long_All</th>
                          <th>Conc_Gross_LE_8_TDR_Short_All</th>
                          <th>Conc_Net_LE_4_TDR_Long_All</th>
                          <th>Conc_Net_LE_4_TDR_Short_All</th>
                          <th>Conc_Net_LE_8_TDR_Long_All</th>
                          <th>Conc_Net_LE_8_TDR_Short_All</th>
                          <th>Conc_Gross_LE_4_TDR_Long_Old</th>
                          <th>Conc_Gross_LE_4_TDR_Short_Old</th>
                          <th>Conc_Gross_LE_8_TDR_Long_Old</th>
                          <th>Conc_Gross_LE_8_TDR_Short_Old</th>
                          <th>Conc_Net_LE_4_TDR_Long_Old</th>
                          <th>Conc_Net_LE_4_TDR_Short_Old</th>
                          <th>Conc_Net_LE_8_TDR_Long_Old</th>
                          <th>Conc_Net_LE_8_TDR_Short_Old</th>
                          <th>Conc_Gross_LE_4_TDR_Long_Other</th>
                          <th>Conc_Gross_LE_4_TDR_Short_Other</th>
                          <th>Conc_Gross_LE_8_TDR_Long_Other</th>
                          <th>Conc_Gross_LE_8_TDR_Short_Other</th>
                          <th>Conc_Net_LE_4_TDR_Long_Other</th>
                          <th>Conc_Net_LE_4_TDR_Short_Other</th>
                          <th>Conc_Net_LE_8_TDR_Long_Other</th>
                          <th>Conc_Net_LE_8_TDR_Short_Other</th>
                          <th>Contract_Units</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(option, index) in options" v-bind:key="index">
                            <td>{{ option.name }}</td>
                            <td>{{ option.classification }}</td>
                            <td>{{ option.date }}</td>
                            <td>{{ option.a }}</td>
                            <td>{{ option.b }}</td>
                            <td>{{ option.c }}</td>
                            <td>{{ option.d }}</td>
                            <td>{{ option.e }}</td>
                            <td>{{ option.f }}</td>
                            <td>{{ option.g }}</td>
                            <td>{{ option.h }}</td>
                            <td>{{ option.i }}</td>
                            <td>{{ option.j }}</td>
                            <td>{{ option.k }}</td>
                            <td>{{ option.l }}</td>
                            <td>{{ option.m }}</td>
                            <td>{{ option.n }}</td>
                            <td>{{ option.o }}</td>
                            <td>{{ option.p }}</td>
                            <td>{{ option.q }}</td>
                            <td>{{ option.r }}</td>
                            <td>{{ option.s }}</td>
                            <td>{{ option.t }}</td>
                            <td>{{ option.u }}</td>
                            <td>{{ option.v }}</td>
                            <td>{{ option.w }}</td>
                            <td>{{ option.x }}</td>
                            <td>{{ option.y }}</td>
                            <td>{{ option.z }}</td>
                            <td>{{ option.aa }}</td>
                            <td>{{ option.ab }}</td>
                            <td>{{ option.ac }}</td>
                            <td>{{ option.ad }}</td>
                            <td>{{ option.ae }}</td>
                            <td>{{ option.af }}</td>
                            <td>{{ option.ag }}</td>
                            <td>{{ option.ah }}</td>
                            <td>{{ option.ai }}</td>
                            <td>{{ option.aj }}</td>
                            <td>{{ option.ak }}</td>
                            <td>{{ option.al }}</td>
                            <td>{{ option.am }}</td>
                            <td>{{ option.an }}</td>
                            <td>{{ option.ao }}</td>
                            <td>{{ option.ap }}</td>
                            <td>{{ option.aq }}</td>
                            <td>{{ option.ar }}</td>
                            <td>{{ option.ass }}</td>
                            <td>{{ option.at }}</td>
                            <td>{{ option.au }}</td>
                            <td>{{ option.av }}</td>
                            <td>{{ option.aw }}</td>
                            <td>{{ option.ax }}</td>
                            <td>{{ option.ay }}</td>
                            <td>{{ option.az }}</td>
                            <td>{{ option.ba }}</td>
                            <td>{{ option.bb }}</td>
                            <td>{{ option.bc }}</td>
                            <td>{{ option.bd }}</td>
                            <td>{{ option.be }}</td>
                            <td>{{ option.bf }}</td>
                            <td>{{ option.bg }}</td>
                            <td>{{ option.bh }}</td>
                            <td>{{ option.bi }}</td>
                            <td>{{ option.bj }}</td>
                            <td>{{ option.bk }}</td>
                            <td>{{ option.bl }}</td>
                            <td>{{ option.bm }}</td>
                            <td>{{ option.bn }}</td>
                            <td>{{ option.bo }}</td>
                            <td>{{ option.bp }}</td>
                            <td>{{ option.bq }}</td>
                            <td>{{ option.br }}</td>
                            <td>{{ option.bs }}</td>
                            <td>{{ option.bt }}</td>
                            <td>{{ option.bu }}</td>
                            <td>{{ option.bv }}</td>
                            <td>{{ option.bw }}</td>
                            <td>{{ option.bx }}</td>
                            <td>{{ option.byy }}</td>
                            <td>{{ option.bz }}</td>
                            <td>{{ option.ca }}</td>
                            <td>{{ option.cb }}</td>
                            <td>{{ option.cc }}</td>
                            <td>{{ option.cd }}</td>
                            <td>{{ option.ce }}</td>
                            <td>{{ option.cf }}</td>
                            <td>{{ option.cg }}</td>
                            <td>{{ option.ch }}</td>
                            <td>{{ option.ci }}</td>
                            <td>{{ option.cj }}</td>
                            <td>{{ option.ck }}</td>
                            <td>{{ option.cl }}</td>
                            <td>{{ option.cm }}</td>
                            <td>{{ option.cn }}</td>
                            <td>{{ option.co }}</td>
                            <td>{{ option.cp }}</td>
                            <td>{{ option.cq }}</td>
                            <td>{{ option.cr }}</td>
                            <td>{{ option.cs }}</td>
                            <td>{{ option.ct }}</td>
                            <td>{{ option.cu }}</td>
                            <td>{{ option.cv }}</td>
                            <td>{{ option.cw }}</td>
                            <td>{{ option.cx }}</td>
                            <td>{{ option.cy }}</td>
                            <td>{{ option.cz }}</td>
                            <td>{{ option.da }}</td>
                            <td>{{ option.db }}</td>
                            <td>{{ option.dc }}</td>
                            <td>{{ option.dd }}</td>
                            <td>{{ option.de }}</td>
                            <td>{{ option.df }}</td>
                            <td>{{ option.dg }}</td>
                            <td>{{ option.dh }}</td>
                            <td>{{ option.di }}</td>
                            <td>{{ option.dj }}</td>
                            <td>{{ option.dk }}</td>
                            <td>{{ option.dl }}</td>
                            <td>{{ option.dm }}</td>
                            <td>{{ option.dn }}</td>
                            <td>{{ option.do }}</td>
                            <td>{{ option.dp }}</td>
                            <td>{{ option.dq }}</td>
                            <td>{{ option.dr }}</td>
                            <td>{{ option.ds }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'ViewLegacyOptions',
  data() {
    return {
      options: [],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
  },
  methods: {
    getOptions() {
      axios.get(`/legacies/getOptions.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.options = response.data.options;
        });
    },
  },
  mounted() {
    this.getOptions();
  },
};
</script>
