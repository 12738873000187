<template>
    <div class="grid-container">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <h4>Open Interest data for: {{ openInterests[0].name }}</h4>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-4">
          <label>Change data set</label>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-4">
          <select v-model="dataSet">
            <option
              v-for="(exchange, index) in exchanges"
              v-bind:key="index"
              v-bind:value="exchange.name">
              {{ exchange.name }}
            </option>
          </select>
        </div>
        <div class="cell small-12 medium-4">
          <button v-on:click="changeData" class="button">Change Data Set</button>
        </div>
      </div>
      <hr />
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-2">
          <label>Date Range Start</label>
          <datepicker id="crs" v-model="dateRangeStart"></datepicker>
        </div>
        <div class="cell small-12 medium-2">
          <label>Date Range End</label>
          <datepicker id="cre" v-model="dateRangeEnd"></datepicker>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
          <div class="cell small-12">
              <h1>Open Interest</h1>
          </div>
      </div>
      <div class="grid-x grid-padding-x">
          <div class="cell small-12">
              <table class="wide-table">
                  <thead>
                      <tr>
                          <th>Day</th>
                          <th>Import Date</th>
                          <th>Name</th>
                          <th>Type</th>
                          <th>Globex</th>
                          <th>Open Outcry</th>
                          <th>Clear Port</th>
                          <th class="blue">Volume</th>
                          <th class="red">Open Interest</th>
                          <th>Change</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(interest, index) in filteredInterests" v-bind:key="index">
                          <td>{{ interest.day }}</td>
                          <td>{{ interest.import_date | moment("Do MMMM YYYY") }}</td>
                          <td>{{ interest.name }}</td>
                          <td>{{ interest.type }}</td>
                          <td>{{ interest.globex }}</td>
                          <td>{{ interest.open_outcry }}</td>
                          <td>{{ interest.clear_port }}</td>
                          <td class="blue">{{ interest.volunme }}</td>
                          <td class="red">{{ interest.open_interest }}</td>
                          <td>{{ interest.change_level }}</td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import axios from '../axios';

export default {
  name: 'ViewOpenInterest',
  data() {
    return {
      exchanges: [],
      openInterests: [],
      filteredInterests: [],
      dataSet: 'Euro FX Futures',
      dateRangeStart: new Date(2021, 0, 1),
      dateRangeEnd: new Date(),
    };
  },
  components: {
    Datepicker,
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
  },
  watch: {
    dateRangeStart(value) {
      const newDate = new Date(value);
      this.filteredInterests = this.openInterests;
      // eslint-disable-next-line
      this.filteredInterests = this.filteredInterests.filter((insterest) => (new Date(insterest.import_date) > newDate));
      // eslint-disable-next-line
      this.filteredInterests = this.filteredInterests.filter((interest) => (new Date(interest.import_date) < this.dateRangeEnd));
    },
    dateRangeEnd(value) {
      const newDate = new Date(value);
      this.filteredInterests = this.openInterests;
      // eslint-disable-next-line
      this.filteredInterests = this.filteredInterests.filter((interest) => (new Date(interest.import_date) < newDate));
      // eslint-disable-next-line
      this.filteredInterests = this.filteredInterests.filter((interest) => (new Date(interest.import_date) > this.dateRangeStart));
    },
    dataType(value) {
      this.openInterests = [];
      axios.get(`/openInterests/get/${value}.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.openInterests = response.data.openInterests;
          this.filteredIterests = response.data.openInterests;
          // eslint-disable-next-line
          this.filteredIterests = this.filteredInterests.filter((interest) => (new Date(interest.date) > this.dateRangeStart));
          // eslint-disable-next-line
          this.filteredInterests = this.filteredInterests.filter((interest) => (new Date(interest.date) < this.dateRangeEnd));
        });
    },
  },
  methods: {
    getExchanges() {
      axios.get(`/openInterests/getExchanges.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.exchanges = response.data.exchanges;
        });
    },
    getOpenInterests() {
      axios.get(`/openInterests/get.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.openInterests = response.data.openInterests;
          this.filteredInterests = response.data.openInterests;
        });
    },
    changeData() {
      this.interests = [];
      axios.get(`/openInterests/get.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}&exchange=${this.dataSet}`)
        .then((response) => {
          this.openInterests = response.data.openInterests;
          this.filteredInterests = response.data.openInterests;
          // eslint-disable-next-line
          this.filteredInterests = this.filteredInterests.filter((interest) => (new Date(interest.import_date) > this.dateRangeStart));
          // eslint-disable-next-line
          this.filteredInterests = this.filteredInterests.filter((interest) => (new Date(interest.import_date) < this.dateRangeEnd));
        });
    },
  },
  mounted() {
    this.getExchanges();
    this.getOpenInterests();
  },
};
</script>
