<template>
    <div class="grid-container">
        <div class="grid-x grid-padding-x">
            <div class="cell small-12">
                <h1>View Legacy Combined</h1>
            </div>
        </div>
        <div class="grid-x grid-padding-x">
            <div class="cell small-12">
                <table class="wide-table">
                    <thead>
                        <tr>
                          <th>Market_and_Exchange_Names</th>
                          <th>As_of_Date_In_Form_YYMMDD</th>
                          <th>Report_Date_as_MM_DD_YYYY</th>
                          <th>CFTC_Contract_Market_Code</th>
                          <th>CFTC_Market_Code</th>
                          <th>CFTC_Region_Code</th>
                          <th>CFTC_Commodity_Code</th>
                          <th>Open_Interest_All</th>
                          <th>NonComm_Positions_Long_All</th>
                          <th>NonComm_Positions_Short_All</th>
                          <th>NonComm_Postions_Spread_All</th>
                          <th>Comm_Positions_Long_All</th>
                          <th>Comm_Positions_Short_All</th>
                          <th>Tot_Rept_Positions_Long_All</th>
                          <th>Tot_Rept_Positions_Short_All</th>
                          <th>NonRept_Positions_Long_All</th>
                          <th>NonRept_Positions_Short_All</th>
                          <th>Open_Interest_Old</th>
                          <th>NonComm_Positions_Long_Old</th>
                          <th>NonComm_Positions_Short_Old</th>
                          <th>NonComm_Positions_Spread_Old</th>
                          <th>Comm_Positions_Long_Old</th>
                          <th>Comm_Positions_Short_Old</th>
                          <th>Tot_Rept_Positions_Long_Old</th>
                          <th>Tot_Rept_Positions_Short_Old</th>
                          <th>NonRept_Positions_Long_Old</th>
                          <th>NonRept_Positions_Short_Old</th>
                          <th>Open_Interest_Other</th>
                          <th>NonComm_Positions_Long_Other</th>
                          <th>NonComm_Positions_Short_Other</th>
                          <th>NonComm_Positions_Spread_Other</th>
                          <th>Comm_Positions_Long_Other</th>
                          <th>Comm_Positions_Short_Other</th>
                          <th>Tot_Rept_Positions_Long_Other</th>
                          <th>Tot_Rept_Positions_Short_Other</th>
                          <th>NonRept_Positions_Long_Other</th>
                          <th>NonRept_Positions_Short_Other</th>
                          <th>Change_in_Open_Interest_All</th>
                          <th>Change_in_NonComm_Long_All</th>
                          <th>Change_in_NonComm_Short_All</th>
                          <th>Change_in_NonComm_Spead_All</th>
                          <th>Change_in_Comm_Long_All</th>
                          <th>Change_in_Comm_Short_All</th>
                          <th>Change_in_Tot_Rept_Long_All</th>
                          <th>Change_in_Tot_Rept_Short_All</th>
                          <th>Change_in_NonRept_Long_All</th>
                          <th>Change_in_NonRept_Short_All</th>
                          <th>Pct_of_Open_Interest_All</th>
                          <th>Pct_of_OI_NonComm_Long_All</th>
                          <th>Pct_of_OI_NonComm_Short_All</th>
                          <th>Pct_of_OI_NonComm_Spread_All</th>
                          <th>Pct_of_OI_Comm_Long_All</th>
                          <th>Pct_of_OI_Comm_Short_All</th>
                          <th>Pct_of_OI_Tot_Rept_Long_All</th>
                          <th>Pct_of_OI_Tot_Rept_Short_All</th>
                          <th>Pct_of_OI_NonRept_Long_All</th>
                          <th>Pct_of_OI_NonRept_Short_All</th>
                          <th>Pct_of_Open_Interest_Old</th>
                          <th>Pct_of_OI_NonComm_Long_Old</th>
                          <th>Pct_of_OI_NonComm_Short_Old</th>
                          <th>Pct_of_OI_NonComm_Spread_Old</th>
                          <th>Pct_of_OI_Comm_Long_Old</th>
                          <th>Pct_of_OI_Comm_Short_Old</th>
                          <th>Pct_of_OI_Tot_Rept_Long_Old</th>
                          <th>Pct_of_OI_Tot_Rept_Short_Old</th>
                          <th>Pct_of_OI_NonRept_Long_Old</th>
                          <th>Pct_of_OI_NonRept_Short_Old</th>
                          <th>Pct_of_Open_Interest_Other</th>
                          <th>Pct_of_OI_NonComm_Long_Other</th>
                          <th>Pct_of_OI_NonComm_Short_Other</th>
                          <th>Pct_of_OI_NonComm_Spread_Other</th>
                          <th>Pct_of_OI_Comm_Long_Other</th>
                          <th>Pct_of_OI_Comm_Short_Other</th>
                          <th>Pct_of_OI_Tot_Rept_Long_Other</th>
                          <th>Pct_of_OI_Tot_Rept_Short_Other</th>
                          <th>Pct_of_OI_NonRept_Long_Other</th>
                          <th>Pct_of_OI_NonRept_Short_Other</th>
                          <th>Traders_Tot_All</th>
                          <th>Traders_NonComm_Long_All</th>
                          <th>Traders_NonComm_Short_All</th>
                          <th>Traders_NonComm_Spread_All</th>
                          <th>Traders_Comm_Long_All</th>
                          <th>Traders_Comm_Short_All</th>
                          <th>Traders_Tot_Rept_Long_All</th>
                          <th>Traders_Tot_Rept_Short_All</th>
                          <th>Traders_Tot_Old</th>
                          <th>Traders_NonComm_Long_Old</th>
                          <th>Traders_NonComm_Short_Old</th>
                          <th>Traders_NonComm_Spead_Old</th>
                          <th>Traders_Comm_Long_Old</th>
                          <th>Traders_Comm_Short_Old</th>
                          <th>Traders_Tot_Rept_Long_Old</th>
                          <th>Traders_Tot_Rept_Short_Old</th>
                          <th>Traders_Tot_Other</th>
                          <th>Traders_NonComm_Long_Other</th>
                          <th>Traders_NonComm_Short_Other</th>
                          <th>Traders_NonComm_Spread_Other</th>
                          <th>Traders_Comm_Long_Other</th>
                          <th>Traders_Comm_Short_Other</th>
                          <th>Traders_Tot_Rept_Long_Other</th>
                          <th>Traders_Tot_Rept_Short_Other</th>
                          <th>Conc_Gross_LE_4_TDR_Long_All</th>
                          <th>Conc_Gross_LE_4_TDR_Short_All</th>
                          <th>Conc_Gross_LE_8_TDR_Long_All</th>
                          <th>Conc_Gross_LE_8_TDR_Short_All</th>
                          <th>Conc_Net_LE_4_TDR_Long_All</th>
                          <th>Conc_Net_LE_4_TDR_Short_All</th>
                          <th>Conc_Net_LE_8_TDR_Long_All</th>
                          <th>Conc_Net_LE_8_TDR_Short_All</th>
                          <th>Conc_Gross_LE_4_TDR_Long_Old</th>
                          <th>Conc_Gross_LE_4_TDR_Short_Old</th>
                          <th>Conc_Gross_LE_8_TDR_Long_Old</th>
                          <th>Conc_Gross_LE_8_TDR_Short_Old</th>
                          <th>Conc_Net_LE_4_TDR_Long_Old</th>
                          <th>Conc_Net_LE_4_TDR_Short_Old</th>
                          <th>Conc_Net_LE_8_TDR_Long_Old</th>
                          <th>Conc_Net_LE_8_TDR_Short_Old</th>
                          <th>Conc_Gross_LE_4_TDR_Long_Other</th>
                          <th>Conc_Gross_LE_4_TDR_Short_Other</th>
                          <th>Conc_Gross_LE_8_TDR_Long_Other</th>
                          <th>Conc_Gross_LE_8_TDR_Short_Other</th>
                          <th>Conc_Net_LE_4_TDR_Long_Other</th>
                          <th>Conc_Net_LE_4_TDR_Short_Other</th>
                          <th>Conc_Net_LE_8_TDR_Long_Other</th>
                          <th>Conc_Net_LE_8_TDR_Short_Other</th>
                          <th>Contract_Units</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(instrument, index) in instruments" v-bind:key="index">
                            <td>{{ instrument.name }}</td>
                            <td>{{ instrument.classification }}</td>
                            <td>{{ instrument.date }}</td>
                            <td>{{ instrument.a }}</td>
                            <td>{{ instrument.b }}</td>
                            <td>{{ instrument.c }}</td>
                            <td>{{ instrument.d }}</td>
                            <td>{{ instrument.e }}</td>
                            <td>{{ instrument.f }}</td>
                            <td>{{ instrument.g }}</td>
                            <td>{{ instrument.h }}</td>
                            <td>{{ instrument.i }}</td>
                            <td>{{ instrument.j }}</td>
                            <td>{{ instrument.k }}</td>
                            <td>{{ instrument.l }}</td>
                            <td>{{ instrument.m }}</td>
                            <td>{{ instrument.n }}</td>
                            <td>{{ instrument.o }}</td>
                            <td>{{ instrument.p }}</td>
                            <td>{{ instrument.q }}</td>
                            <td>{{ instrument.r }}</td>
                            <td>{{ instrument.s }}</td>
                            <td>{{ instrument.t }}</td>
                            <td>{{ instrument.u }}</td>
                            <td>{{ instrument.v }}</td>
                            <td>{{ instrument.w }}</td>
                            <td>{{ instrument.x }}</td>
                            <td>{{ instrument.y }}</td>
                            <td>{{ instrument.z }}</td>
                            <td>{{ instrument.aa }}</td>
                            <td>{{ instrument.ab }}</td>
                            <td>{{ instrument.ac }}</td>
                            <td>{{ instrument.ad }}</td>
                            <td>{{ instrument.ae }}</td>
                            <td>{{ instrument.af }}</td>
                            <td>{{ instrument.ag }}</td>
                            <td>{{ instrument.ah }}</td>
                            <td>{{ instrument.ai }}</td>
                            <td>{{ instrument.aj }}</td>
                            <td>{{ instrument.ak }}</td>
                            <td>{{ instrument.al }}</td>
                            <td>{{ instrument.am }}</td>
                            <td>{{ instrument.an }}</td>
                            <td>{{ instrument.ao }}</td>
                            <td>{{ instrument.ap }}</td>
                            <td>{{ instrument.aq }}</td>
                            <td>{{ instrument.ar }}</td>
                            <td>{{ instrument.ass }}</td>
                            <td>{{ instrument.at }}</td>
                            <td>{{ instrument.au }}</td>
                            <td>{{ instrument.av }}</td>
                            <td>{{ instrument.aw }}</td>
                            <td>{{ instrument.ax }}</td>
                            <td>{{ instrument.ay }}</td>
                            <td>{{ instrument.az }}</td>
                            <td>{{ instrument.ba }}</td>
                            <td>{{ instrument.bb }}</td>
                            <td>{{ instrument.bc }}</td>
                            <td>{{ instrument.bd }}</td>
                            <td>{{ instrument.be }}</td>
                            <td>{{ instrument.bf }}</td>
                            <td>{{ instrument.bg }}</td>
                            <td>{{ instrument.bh }}</td>
                            <td>{{ instrument.bi }}</td>
                            <td>{{ instrument.bj }}</td>
                            <td>{{ instrument.bk }}</td>
                            <td>{{ instrument.bl }}</td>
                            <td>{{ instrument.bm }}</td>
                            <td>{{ instrument.bn }}</td>
                            <td>{{ instrument.bo }}</td>
                            <td>{{ instrument.bp }}</td>
                            <td>{{ instrument.bq }}</td>
                            <td>{{ instrument.br }}</td>
                            <td>{{ instrument.bs }}</td>
                            <td>{{ instrument.bt }}</td>
                            <td>{{ instrument.bu }}</td>
                            <td>{{ instrument.bv }}</td>
                            <td>{{ instrument.bw }}</td>
                            <td>{{ instrument.bx }}</td>
                            <td>{{ instrument.byy }}</td>
                            <td>{{ instrument.bz }}</td>
                            <td>{{ instrument.ca }}</td>
                            <td>{{ instrument.cb }}</td>
                            <td>{{ instrument.cc }}</td>
                            <td>{{ instrument.cd }}</td>
                            <td>{{ instrument.ce }}</td>
                            <td>{{ instrument.cf }}</td>
                            <td>{{ instrument.cg }}</td>
                            <td>{{ instrument.ch }}</td>
                            <td>{{ instrument.ci }}</td>
                            <td>{{ instrument.cj }}</td>
                            <td>{{ instrument.ck }}</td>
                            <td>{{ instrument.cl }}</td>
                            <td>{{ instrument.cm }}</td>
                            <td>{{ instrument.cn }}</td>
                            <td>{{ instrument.co }}</td>
                            <td>{{ instrument.cp }}</td>
                            <td>{{ instrument.cq }}</td>
                            <td>{{ instrument.cr }}</td>
                            <td>{{ instrument.cs }}</td>
                            <td>{{ instrument.ct }}</td>
                            <td>{{ instrument.cu }}</td>
                            <td>{{ instrument.cv }}</td>
                            <td>{{ instrument.cw }}</td>
                            <td>{{ instrument.cx }}</td>
                            <td>{{ instrument.cy }}</td>
                            <td>{{ instrument.cz }}</td>
                            <td>{{ instrument.da }}</td>
                            <td>{{ instrument.db }}</td>
                            <td>{{ instrument.dc }}</td>
                            <td>{{ instrument.dd }}</td>
                            <td>{{ instrument.de }}</td>
                            <td>{{ instrument.df }}</td>
                            <td>{{ instrument.dg }}</td>
                            <td>{{ instrument.dh }}</td>
                            <td>{{ instrument.di }}</td>
                            <td>{{ instrument.dj }}</td>
                            <td>{{ instrument.dk }}</td>
                            <td>{{ instrument.dl }}</td>
                            <td>{{ instrument.dm }}</td>
                            <td>{{ instrument.dn }}</td>
                            <td>{{ instrument.do }}</td>
                            <td>{{ instrument.dp }}</td>
                            <td>{{ instrument.dq }}</td>
                            <td>{{ instrument.dr }}</td>
                            <td>{{ instrument.ds }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'ViewLegacyCombined',
  data() {
    return {
      instruments: [],
    };
  },
  computed: {
    authData() {
      return this.$store.getters.authData;
    },
  },
  methods: {
    getInstruments() {
      axios.get(`/legacies/getInstruments.json?auth=${this.authData.token}&user=${this.authData.userId}&cage=${this.authData.cage}&level=${this.userLevel}`)
        .then((response) => {
          this.instruments = response.data.instruments;
        });
    },
  },
  mounted() {
    this.getInstruments();
  },
};
</script>
